// app/javascript/application.js
// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import "./controllers"

// TODO selectively load trix/actiontext
import "trix"
import "@rails/actiontext"

import LocalTime from "local-time"
LocalTime.start()

import { ready } from "dom-boilerplate";
import Flex from '@flex/hack-core';
import { application } from "./controllers/application"

Flex.start(application)

ready(() => {
  // Add your code here

  console.log('[ document ready ]')
})

console.log('[ application.js loaded ]')

//document.addEventListener("turbo:before-stream-render", function (event) {
//  const fallbackToDefaultActions = event.detail.render
//
//  event.detail.render = function (streamElement) {
//    console.log('[ turbo:before-stream-render ]', streamElement)
//
//    if (streamElement.action == "foobar") {
//      // ...
//    } else if (streamElement.action == "foobaz") {
//      // ...
//    } else {
//      fallbackToDefaultActions(streamElement)
//    }
//  }
//})