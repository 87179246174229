import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--affiliate-parser"
//
// This parses the htmlSource for the a and img
//
// Add to textarea, etc where avant link will be pasted
// data: {
//   'action': 'keyup->admin--affiliate-parser#parse',
//   'admin--affiliate-parser-target': 'htmlSource'
// },
//
// Add to text input for affliate url:
// data: {
//   'admin--affiliate-parser-target': 'linkUrl'
// },
//
// Add to text input for image url:
// data: {
//   'admin--affiliate-parser-target': 'imageUrl'
// },
export default class extends Controller {
  static targets = ['htmlSource', 'linkUrl', 'imageUrl']

  parse(event) {
    const source = this.htmlSourceTarget.value
    const parser = new DOMParser()
    const doc = parser.parseFromString(source, "text/html");
    const anchor = doc.querySelector('a')
    const image = doc.querySelector('img')

    if(anchor && this.hasLinkUrlTarget) {
      this.linkUrlTarget.value = anchor.href
    }
    if(image && this.hasImageUrlTarget) {
      this.imageUrlTarget.value = image.src
    }
  }
}
